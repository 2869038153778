// 휴대폰번호
export const mobileRegex = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;

// 이메일
export const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

// 생년월일
export const birthRegex = /^[0-9]{6}$/;

// 주민등록번호
export const juminRegex = /^[0-9]{13}$/;

// 사업자등록번호
export const bizIdRegex = /^[0-9]{10}$/;

// 웹사이트 주소
export const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

// 전화번호
export const telRegex = /^(01[016789]\d{7,8}|0[2-9]\d{1,2}\d{6,8})$/;

// 리워드 팔찌 각인
export const fourthRegex = /^[A-Z]{1,5}$/;
